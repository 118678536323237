<template>
  <div class="GrowthSystem clearfix">
    <div class="title">
      <h3>成长体系</h3>
    </div>
    <div class="raius">
      <div class="content">
        <div class="left">
          <p style="position: absolute; top: 100px; left: 164px">大师鉴赏</p>
          <p style="position: absolute; left: 58px; top: 300px">创意美术</p>
          <p style="position: absolute; right: 26px; top: 300px">多元文化</p>
          <h3>儿童心理成长规律</h3>
          <h3 style="top: 52%">（天性）</h3>
        </div>
        <div class="centent">
          <div style="top: -8px">
            <p>审美</p>
            <p>APPRECIATION</p>
            <p>OF THE BEAUTY</p>
          </div>
          <div style="top: 4px">
            <p>思维</p>
            <p>THINKING</p>
          </div>
          <div style="top: 18px">
            <p>修养</p>
            <p>ACCOMP</p>
            <p>LISHMENT</p>
          </div>
        </div>
        <div class="right">
          <div class="top-txt">
            <p>极简之美、极繁之美、留白之美、对比之美、</p>
            <p>朦胧之美、粗犷之美、狞厉之美、结构之美、</p>
            <p>对称之美、秩序之美、平衡之美、残缺之美、</p>
            <p>几何之美、沧桑之美、轻柔之美、平衡之美。</p>
          </div>
          <div class="top-txt">
            <p>放大法、转移法、缩小法、联想法、无限法、</p>
            <p>逆向法、重组法、聚焦法、重构法。</p>
          </div>
          <div class="top-txt">
            <p>中国传统特色文化、国外异域文化</p>
          </div>
        </div>
      </div>
    </div>
    <div class="info-txt">
      <p>
        艺途国际•艺术教育拥有美术学科3-15岁孩子的完整课程体系，该课程体系是以美国国家艺术培养体系K-12艺术教育体系为基础，同时结合欧洲艺术教育、教学体系与中国国家美术教育学科核心要素为依据、按照专业美术学习的螺旋递进原理，依据学生的年龄，生理，心理，特质，目标等因素而设置。以三大多维度课程为核心，包含主要四个课程内容。每一堂课程主题不同，但相互关联延伸，让孩子通过学习艺术，激发创意性地理解思考事物的能力。
      </p>
      <br>
      <p style="font-size: 14px;text-align: justify;">Yitu International Art Education has a complete curriculum system for children aged 3-15 in the field of fine arts. This curriculum system is based on the K-12 art education system of the US national art training system, combined with the core elements of European art education and teaching systems, as well as the Chinese national art education discipline. According to the spiral progression principle of professional art learning, it is designed based on factors such as students' age, physiology, psychology, traits, goals, etc. With three major dimensions of curriculum as the core, it includes four main course contents. Each course has different themes, but they are interconnected and extended, allowing children to learn art and stimulate their creative ability to understand and think about things</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.GrowthSystem {
  width: 100%;
  background: url(../../../assets/img/GrowthSystemBg.png) no-repeat center;
  background-size: cover;
  height: 936px;
  .title {
    text-align: center;
    margin: 0px auto;
    margin-top: 70px;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .raius {
    width: 1007px;
    height: 466px;
    background: url(../../../assets/img/ppt.png) no-repeat center;
    background-size: cover;
    margin: 50px auto;
    .content {
      width: 100%;
      display: flex;
      height: 466px;
      justify-content: space-between;
      .left {
        width: 363px;
        height: 100%;
        border-radius: 50%;
        position: relative;
        p {
          position: absolute;

          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        h3 {
          width: 100%;
          font-size: 18px;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          position: absolute;
          top: 46%;
          left: 55%;
          transform: translate(-50%);
        }
      }
      .centent {
        width: 24%;
        height: 100%;
        div {
          height: 33%;
          font-weight: bold;
          color: #ffffff;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          p {
            font-size: 14px;
          }
        }
      }
      .right {
        width: 38%;
        height: 100%;
        .top-txt {
          height: 160px;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;

          font-size: 16px;
          font-weight: bold;
          color: #333333;
          text-align: left;
        }
      }
    }
  }
  .info-txt {
    margin: 0 auto;
    width: 1000px;
    height: 160px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    line-height: 23px;

  }
}
</style>