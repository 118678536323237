import { render, staticRenderFns } from "./development.vue?vue&type=template&id=5804cc39&scoped=true&"
import script from "./development.vue?vue&type=script&lang=js&"
export * from "./development.vue?vue&type=script&lang=js&"
import style0 from "./development.vue?vue&type=style&index=0&id=5804cc39&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5804cc39",
  null
  
)

export default component.exports