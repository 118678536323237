<template>
    <div class="banner clearfix">
        <img src="../../../assets/img/Paintingbanner.png" alt="">
    </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>
    .banner{
        width: 100%;
        height: 660px;
        margin-top: 75PX;
        img{
            width: 100%;
            height: 660px;
           
        }
    }
</style>