<template>
    <div>
        <title-yt/>
        <nav-top/>
        <painting-banner/>
        <teachers/>
        <development/>
        <Content/>
        <growth-system/>
        <competency-model/>
        <environment/>
        <brand-advantages/>
        <copyright/>
    </div>
</template>

<script>
import Copyright from '../../pubilic/copyright.vue'
import NavTop from '../../pubilic/NavTop.vue'
import TitleYt from '../../pubilic/TitleYt.vue'
import BrandAdvantages from './BrandAdvantages.vue'
import CompetencyModel from './CompetencyModel.vue'
import Content from './Content.vue'
import Development from './development.vue'
import Environment from './environment.vue'
import GrowthSystem from './GrowthSystem.vue'
import PaintingBanner from './PaintingBanner.vue'
import Teachers from './Teachers.vue'
export default {
  components: { NavTop, TitleYt, PaintingBanner, Teachers, Development, Content, GrowthSystem, CompetencyModel, Environment, BrandAdvantages, Copyright },

}
</script>

<style>

</style>