<template>
  <div class="Content clearfix">
    <div class="title">
      <h3>授课内容</h3>
    </div>
    <div class="imgList">
      <div class="img-item" v-for="(item, i) in imgList" :key="i">
        <div class="img-label">{{ item.label }}</div>
        <img :src="item.imageSrc" alt="" />
      </div>
    </div>
    <div class="title">
      <h3>阶段性培养 个性化施教</h3>
    </div>
    <div class="personalization clearfix">
        
      <div class="left-content">
        <div class="btns">
          <div
            @click="showPersonalization(0)"
            :class="{ active: currentPersonalizationIndex === 0 }"
          >
            兴趣启蒙
          </div>
          <div
            @click="showPersonalization(1)"
            :class="{ active: currentPersonalizationIndex === 1 }"
          >
            系统进阶
          </div>
          <div
            @click="showPersonalization(2)"
            :class="{ active: currentPersonalizationIndex === 2 }"
          >
            专业深造
          </div>
        </div>
        <div class="top-txt">
          <h3>{{ currentPersonalization.age }}</h3>
          <h3>{{ currentPersonalization.students }}</h3>
          <br />
          <p
            v-for="(info, infoIndex) in currentPersonalization.info"
            :key="infoIndex"
          >
            {{ info }}
          </p>
        </div>
      </div>

      <div class="right-img">
        <img :src="currentImageSrc" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentPersonalizationIndex: 0,
      imgList: [
        {
          imageSrc: require("../../../assets/img/sknr1.png"),
          label: "创意绘画",
        },
        {
          imageSrc: require("../../../assets/img/sknr2.png"),
          label: "动漫插画",
        },
        { imageSrc: require("../../../assets/img/sknr3.png"), label: "素描" },
        { imageSrc: require("../../../assets/img/sknr4.png"), label: "国画" },
        { imageSrc: require("../../../assets/img/sknr5.png"), label: "油画" },
        { imageSrc: require("../../../assets/img/sknr6.png"), label: "版画" },
      ],
      personalizationData: [
        {
          age: "兴趣启蒙  K0",
          students: "适合学龄前（3-4岁）学员",
          info: [
            "专为零基础幼儿设置的幼少衔接课程。包含感知色彩、自由",
            "创造、自由表达等内容维度，采用实验体验、游戏互动等创",
            "意方式，培养孩子爱心和行为习惯，带领孩子在玩耍和观察",
            "中爱上美术。",
          ],
        },
        {
          age: "系统进阶  K1-K11",
          students: "适合4-12岁学员",
          info: [
            "覆盖艺途国际少儿自主研发核心教材体系，启智、进阶、拓",
            "展课程科学划分。系统培养色彩理解、空间架构、创意造型",
            "等多元内容，在艺术鉴赏、情景搭建、经典传承中，领略探",
            "索精神和创意思维，提升专注力、自信心和审美能力。",
          ],
        },
        {
          age: "专业深造 K12",
          students: "适合13岁以上学员",
          info: [
            "与专注艺术高考培训二十余年的一线教师接轨，重视专业训",
            "练、技巧提升，衔接艺考专业课程。",
          ],
        },
      ],
      imageArr: [
        require("../../../assets/img/xqqm.png"),
        require("../../../assets/img/xqqm1.png"),
        require("../../../assets/img/xqqm2.png"),
      ],
    };
  },
  computed: {
    currentPersonalization() {
      return this.personalizationData[this.currentPersonalizationIndex];
    },
    currentImageSrc() {
      return this.imageArr[this.currentPersonalizationIndex];
    },
  },
  methods: {
    showPersonalization(index) {
      this.currentPersonalizationIndex = index;
    },
  },
};
</script>

<style lang="less" scoped>
.Content {
  .title {
    text-align: center;
    margin: 0px auto;
    margin-top: 100px;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .imgList {
    width: 1002px;
    height: auto;
    display: flex;
    margin: 60px auto;
    flex-wrap: wrap;
    justify-content: center;

    cursor: pointer;
    .img-item {
      position: relative;
      img {
        width: 234px;
        margin-bottom: 30px;
        margin-left: 15px;
        cursor: pointer;
      }
      .img-label {
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100%;
        text-align: center;

        font-size: 18px;
        font-weight: 500;
        color: #ffffff;
        padding: 5px; /* Optional: Add padding for better readability */
      }
    }
  }
  .personalization {
    width: 1002px;
    height: auto;
    margin: 60px auto;
    display: flex;
    .left-content {
      width: 572px;
      .btns {
        width: 447px;
        display: flex;
        div {
          width: 155px;
          height: 49px;
          text-align: center;
          line-height: 49px;
          background: #e0e0e0;
          border-radius: 25px;
          margin-right: 10px;
          cursor: pointer;
        }
        .activeClass {
          color: white;
          background: #851d29;
        }
      }
      .btns .active {
        background-color: #851d29;
        color: white;
        transition: all 0.3s;
      }
      .top-txt {
        margin-top: 30px;
        transition: all 0.3s;
        h3 {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
        }
        p {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
      }
    }
    .right-img {
      width: 430px;
      height: 316px;
    }
  }
}
</style>
