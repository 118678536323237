<template>
  <div class="development clearfix">
    <div class="title">
      <h3>美术阶梯 成长课程</h3>
    </div>
    <div class="box">
      <div class="left-tiitle">
        <div class="content">
          <span style="height: 70px">年龄</span>
          <span style="height: 70px">级别</span>
          <span style="height: 150px; line-height: 150px">教学内容</span>
          <span style="height: 150px; line-height: 150px"> 教学特色</span>
        </div>
      </div>
      <div class="right-txt">
        <div class="top-tittle">
          <p style="margin-left: -7px">启蒙</p>
          <p>启智</p>
          <p>进阶</p>
          <p>拓展</p>
          <p style="margin-left: 17px">专业</p>
        </div>
        <div class="age">
          <p>3-4岁</p>
          <p>5-6岁</p>
          <p>7-9岁</p>
          <p>10-13岁</p>
          <p>14岁+</p>
        </div>
        <div class="level">
          <p>K0</p>
          <p>K1-3</p>
          <p>K4-6</p>
          <p>K7-11</p>
          <p>K12</p>
        </div>
        <div class="TeachingContent">
          <div>
            <p>感知色彩</p>
            <p>自由创造</p>
            <p>自由表达</p>
            <p>激发爱心</p>
          </div>
          <div>
            <p>色彩理解</p>
            <p>故事分享</p>
            <p>创意思维</p>
            <p>培养自信心</p>
          </div>
          <div>
            <p>综合训练</p>
            <p>审美判断</p>
            <p>综合表现</p>
            <p>培养专注力</p>
          </div>
          <div>
            <p>空间架构</p>
            <p>美术表现</p>
            <p>创意造型</p>
            <p>探索精神</p>
          </div>
          <div>
            <p>专业技巧</p>
            <p>多元文化</p>
            <p>创意表达</p>
            <p>综合素养</p>
          </div>
        </div>
        <div class="characteristic">
          <div>
            <p>实验体验</p>
            <p>游戏互动</p>
            <p>行为习惯</p>
           
          </div>
          <div>
            <p>情景搭建</p>
            <p>剧情演绎</p>
            <p>艺术鉴赏</p>
         
          </div>
          <div>
            <p>历史传承</p>
            <p>文化经典</p>
            <p>思维训练</p>
          
          </div>
          <div>
            <p>写生观察 </p>
            <p>技巧训练</p>
            <p>创造搭建</p>
           
          </div>
          <div>
            <p>专业训练</p>
            <p>技巧提升</p>
            <p>对接考学</p>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.development {
  width: 100%;
  height: 936px;
  background: url(../../../assets/img/developmentBg.png) no-repeat center;
  background-size: cover;
  .title {
    text-align: center;
    margin: 0px auto;
    margin-top: 100px;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .box {
    width: 1000px;
    margin: 0px auto;
    margin-top: 150px;

    height: 511px;
    display: flex;
    .left-tiitle {
      width: 90px;
      height: 100%;

      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      .content {
        height: 436px;
        width: 100%;

        display: flex;
        flex-direction: column;
        span {
          font-size: 18px;
          font-weight: 500;
          color: #851d29;
        }
      }
    }

    .right-txt {
      width: 910px;
      height: 511px;
      background: url(../../../assets/img/czkcBg.png) no-repeat center;
      background-size: cover;
      .top-tittle {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        p {
          width: 166px;
          text-align: right;
          font-size: 26px;
          font-weight: 500;
          color: #ffffff;
          margin-left: 10px;
        }
      }
      .age,
      .level {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        p {
          width: 25%;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .TeachingContent,.characteristic {
        width: 100%;
        height: 164px;
        display: flex;
        align-items: center;
        padding-bottom: 8px;

        div {
          width: 25%;
          text-align: center;
          font-size: 18px;
          font-weight: 500;
          color: #ffffff;
        }
      }
      .characteristic{
        height: 135px;
       
      }
    }
  }
}
</style>