<template>
  <div class="environment clearfix">
    <div class="title">教室环境</div>
    <carousel-3d
      :controlsVisible="true"
      :perspective="0"
      class="box"
      :width="533"
      :height="400"
      :space="180"
    >
      <slide v-for="(slide, i) in slides" :index="i" :key="i">
        <img :src="slide" alt="" />
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  data() {
    return {
      slides: [
        require("../../../assets/img/environment1.png"),
        require("../../../assets/img/environment2.png"),
        require("../../../assets/img/environment3.png"),
        require("../../../assets/img/environment4.png"),
        require("../../../assets/img/environment5.png"),
        require("../../../assets/img/environment6.png"),
        require("../../../assets/img/environment7.png"),
        require("../../../assets/img/environment8.png"),
      ],
    };
  },
  components: {
    Carousel3d,
    Slide,
  },
};
</script>

<style lang="less" scoped>
.environment {
  width: 100%;
  height: 693px;
  background: url(../../../assets/img/environmentBg.png) no-repeat center;
  background-size: cover;
  .title {
    text-align: center;
    margin: 70px auto;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .box {
    width: 980px;
    background: none !important;
    border: none;
  }
}
</style>