import { render, staticRenderFns } from "./PaintingBanner.vue?vue&type=template&id=031f9526&scoped=true&"
import script from "./PaintingBanner.vue?vue&type=script&lang=js&"
export * from "./PaintingBanner.vue?vue&type=script&lang=js&"
import style0 from "./PaintingBanner.vue?vue&type=style&index=0&id=031f9526&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "031f9526",
  null
  
)

export default component.exports