<template>
  <div class="Teachers clearfix">
    <div class="title">师资力量</div>
    <div class="content">
      <div class="left-info clearfix">
        <div class="left-info clearfix">
          <div class="user">
            <div class="name">{{ currentUser.name }}</div>
            <div class="info">
              <p v-for="(info, infoIndex) in currentUser.info" :key="infoIndex">
                {{ info }}
              </p>
            </div>
          </div>
        </div>
        <div class="btns">
          <div
            class="letf-btn normalClass"
            @click="showUserInfo(-1, 0)"
            :class="{ active: activeButtonIndex === 0 }"
          >
            <img src="../../../assets/img/left-up-black.png" ref="left" />
          </div>
          <div
            class="right-btn normalClass"
            @click="showUserInfo(1, 1)"
            :class="{ active: activeButtonIndex === 1 }"
          >
            <img
              class="rotate"
              src="../../../assets/img/left-up-black.png"
              ref="right"
            />
          </div>
        </div>
      </div>
      <div class="right-info">
        <div class="ytTitle">YITU</div>
        <img class="img" :src="currentImageSrc" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeButtonIndex: -1, // index of the currently active button
      currentUserIndex: 0, // index of the currently displayed user
      userInfo: [
      {
          name: "周宇涵",
          info: [
            "北本科毕业于哈尔滨师范大学。",
            "系统学习美术长达16年，先后担任美术蛙、美术宝专职。",
            "美术教师，从事美术教育长达五年，期间教授4-18岁年龄段学员数千名。",
            "教学经验丰富，美术功底深厚擅长素描、速写、动漫、彩铅、水粉等科目，对艺术考级颇有心得，深受学员喜爱。",
          ],
        },
        {
          name: "范雨竹",
          info: [
            "北京航空航天大学交互设计专业硕士。",
            "本科毕业于北京工业大学艺术设计学院。",
            "系统学习美术长达13年，从事美术教育10年，有着扎实的。",
            "美术功底和优秀的教学能力。",
            "擅长K12阶段的各类绘画形式，包含素描、色彩、创意设计",
            "等，对美术考级颇有心得，深受广大学生家长认可。",
          ],
        },
        {
          name: "陈朋",
          info: [
            "毕业于中国戏曲学院视觉传达设计专业。",
            "硕士学历，拥有十年美术老师经验和两年美术教学经验，擅长素描与工笔画创作。",
          ],
        },
       
      ],
      imageSrcList: [
      require("../../../assets/img/zyh.png"),
        require("../../../assets/img/fyz.png"),
        require("../../../assets/img/cp.png"),
       
      ],
    };
  },
  computed: {
    currentUser() {
      return this.userInfo[this.currentUserIndex];
    },
    currentImageSrc() {
      return this.imageSrcList[this.currentUserIndex];
    },
  },
  methods: {
    showUserInfo(increment, buttonIndex) {
      this.currentUserIndex += increment;
      this.currentUserIndex = Math.max(
        0,
        Math.min(this.currentUserIndex, this.userInfo.length - 1)
      );

      this.activeButtonIndex = buttonIndex;
      if (increment === -1) {
        this.$refs.left.src = require("../../../assets/img/left-up-white.png");
        this.$refs.right.src = require("../../../assets/img/left-up-black.png");
        this.$refs.left.className = "rotate";
        this.$refs.right.className = "rotate";
      } else {
        this.$refs.left.src = require("../../../assets/img/left-up-black.png");
        this.$refs.left.className = "";
        this.$refs.right.src = require("../../../assets/img/left-up-white.png");
        this.$refs.right.className = "";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.Teachers {
  padding-bottom: 200px;
  .title {
    text-align: center;
    margin: 50px auto;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
    margin-bottom: 50px;
  }
  .content {
    width: 1000px;
    height: 400px;
    margin: 0 auto;

    display: flex;
    .left-info {
      width: 471px;
      height: 216px;
      margin-top: 50px;
      .name {
        width: 87px;
        height: 48px;
        font-size: 28px;
        font-weight: 500;
        color: #333333;
      }
      .info {
        width: 471px;
        height: 216px;
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
      .btns {
        width: 300px;
        margin: 50px auto;
        height: 60px;
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        img {
          width: 22px;
          height: 36px;
        }
      }

      .normalClass {
        display: flex;
        width: 60px;
        height: 60px;
        background: #d8d8d8;
        border-radius: 39px;
        align-items: center;
        justify-content: center;
      }
      .rotate {
        transform: rotate(180deg);
      }
      .active {
        background-color: #851d29; /* Change this to the desired background color */
      }
    }
    .right-info {
      width: 529px;
      height: 100%;

      .ytTitle {
        width: 497px;
        height: auto;
        font-size: 150px;
        font-weight: 800;
        color: #dedede;
        margin-top: -65px;
      }
      .img {
        position: relative;
        top: -95px;
        left: 50px;
      }
    }
  }
}
</style>