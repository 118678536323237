<template>
  <div class="BrandAdvantages clearfix">
    <div class="title">品牌优势</div>
    <div class="content">
      <div class="left">
        <div class="txt-top">
          <h3>科学的教育方法</h3>
          <p>专业、专注、创新、激发兴趣、</p>
          <p>丰富想象力、开拓创造力。</p>
        </div>
        <div class="txt-footer">
          <h3>专业的师资力量</h3>
          <p>完备的教研体系，独立开发的教</p>
          <p>育教学系统，完善的教学流程。</p>
        </div>
      </div>
      <div class="centent-img">
        <img src="../../../assets/img/ppys.png" alt="" />
      </div>
      <div class="right">
        <div class="txt-top">
          <h3>N对1的个性化服务</h3>
          <p>艺术培训课程顾问服务，优师资</p>
          <p>匹配，学管师服务，多对一教学</p>
          <p>服务。</p>
        </div>
        <div class="txt-footer">
          <h3>优秀学员重点培养</h3>
          <p>优异者可获得更大的成长空间和</p>
          <p>舞台，提供艺术游学、电视录制</p>
          <p>等机会</p>
        </div>
      </div>
    </div>
    <div class="img-show">
        <img src="../../../assets/img/rongyu1.png" alt="">
        <img src="../../../assets/img/rongyu.png" alt="">
    </div>
    <div class="title">舞台实践，星光璀璨</div>
    <div class="info">
        为每个孩子规划阶梯式成长体系，从艺术教学到艺术实践（考级、比赛、活动），让孩子在天籁少儿享受艺术、享受精彩童年，实现能力和个性的全方位蜕变。
    </div>
    <div class="bg"></div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.BrandAdvantages {
  .title {
    text-align: center;
    margin: 70px auto;
    font-size: 32px;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
  }
  .content {
    width: 1002px;
    margin: 0 auto;
    height: 351px;
    display: flex;
    justify-content: space-between;
    .left,
    .right {
      width: 257px;
      height: 260px;
      display: flex;
      flex-direction: column;
    align-items: center;
    justify-content: space-between;
      .txt-top,.txt-footer{
        display: flex;
        flex-direction: column;
      }
      h3 {
        font-size: 20px;
        font-weight: bold;
        color: #831925;
      }
      p {
        font-size: 18px;
        font-weight: 500;
        color: #333333;
      }
    }
  }
  .img-show{
    margin: 0 auto;
    display: flex;
    width: 932px;
    justify-content: space-between;
  }
  .info{
    margin: 0 auto;
    width: 1017px;
height: 56px;
font-size: 18px;
font-family: SourceHanSansCN-Medium, SourceHanSansCN;
font-weight: 500;
color: #333333;
line-height: 28px;
  }
  .bg{
    width: 841px;
    height: 313px;
    background: url(../../../assets/img/rongyuBg.png) no-repeat center;
    background-size: cover;
    margin: 0 auto;
    margin-top: 45px;
    
  }
  padding-bottom: 45px;
}
</style>